























































import { cloneDeep } from "lodash";
import { ValidationObserver } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import TextareaField from "@/components/form/TextareaField.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";
import ValidationTextareaField from "@/components/form/ValidationTextareaField.vue";
import ValidationCheckboxField from "@/components/form/ValidationCheckboxField.vue";
import { TaskType } from "@/types/API/QuestionKitsType";
import { TASK_EMPTY_DATA } from "@/config/taskEmptyData";
import { HttpErrorListType } from "@/types/API/HttpErrorType";
import { ObjectOf } from "@/types/ObjectOf";

@Component({
  components: {
    MaterialIcon,
    TextareaField,
    CheckboxField,
    ValidationObserver,
    ValidationTextareaField,
    ValidationCheckboxField,
  },
})
export default class TaskEditor extends Vue {
  @Prop({ type: Object, default: TASK_EMPTY_DATA }) data!: TaskType;
  @Prop({ type: Object, default: () => ({}) })
  validateErrorList!: HttpErrorListType;

  public localData: TaskType = TASK_EMPTY_DATA;

  get hasCheckedAnswer(): boolean {
    return this.localData.answers.some((el) => el.is_right);
  }

  @Watch("validateErrorList")
  updateErrorList(errors: HttpErrorListType): void {
    (this.$refs.form as ObjectOf<any>).setErrors(errors);
  }

  created(): void {
    this.localData = cloneDeep(this.data);
  }

  setCheckRightAnswer(checked: boolean, index: number): void {
    this.localData.answers.forEach((answer) => {
      answer.is_right = false;
    });

    this.localData.answers[index].is_right = checked;
    this.updateData();
  }

  @Emit()
  updateData(): TaskType {
    return this.localData;
  }
}
