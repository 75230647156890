






















import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CheckboxField from "@/components/form/CheckboxField.vue";
import ValidationFieldMixin from "@/mixins/ValidationFieldMixin";

@Component({
  components: {
    CheckboxField,
  },
})
export default class ValidationCheckboxField extends mixins(
  ValidationFieldMixin
) {
  @Prop({ type: Boolean, default: false }) isSwitcher!: boolean;
}
