

















import { Prop, Emit } from "vue-property-decorator";
import Component, { mixins } from "vue-class-component";
import InputField from "@/components/form/InputField.vue";
import { ObjectOf } from "@/types/ObjectOf";
import { InputValueType } from "@/types/form/InputValueType";
import { TEXTAREA_MIN_HEIGHT } from "@/constants/formInputsConstants";

@Component
export default class TextareaField extends mixins(InputField) {
  @Prop({ type: Number, default: TEXTAREA_MIN_HEIGHT }) minHeight!: number;

  private inputHeight = 0;

  get inputStyle(): ObjectOf<string> {
    return {
      "min-height":
        this.minHeight < this.inputHeight
          ? `calc(${this.inputHeight}px + 0.5em)`
          : "auto",
      height: `${this.minHeight}px`,
    };
  }

  mounted(): void {
    this.resize();
  }

  private resize(): void {
    this.inputHeight = (this.$refs.textarea as Element).scrollHeight;
  }

  @Emit()
  public input({ target }: { target: HTMLInputElement }): InputValueType {
    return target.value;
  }
}
