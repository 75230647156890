























import { Emit, Prop } from "vue-property-decorator";
import Component, { mixins } from "vue-class-component";
import FormFieldMixin from "@/mixins/FormFieldMixin";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";
import { InputValueType } from "@/types/form/InputValueType";

@Component({
  components: { MaterialIcon },
})
export default class CheckboxField extends mixins(FormFieldMixin) {
  @Prop({ type: Boolean, default: false }) isSwitcher!: boolean;

  @Emit()
  public input({ target }: { target: HTMLInputElement }): InputValueType {
    return target.checked;
  }
}
