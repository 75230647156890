



































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { TaskType } from "@/types/API/QuestionKitsType";

@Component
export default class TaskPreview extends Vue {
  @Prop({ type: Object, required: true }) data!: TaskType;
  @Prop({ type: Boolean, default: true }) showEditBtn!: boolean;

  @Emit()
  showEditPanel(): void {
    return;
  }
}
