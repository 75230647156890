















































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";

@Component({
  components: { MaterialIcon },
})
export default class Pagination extends Vue {
  @Prop({ type: Number, required: true }) value!: number;
  @Prop({ type: Number, default: 0 }) total!: number;
  @Prop({ type: Number, default: 20 }) perPage!: number;
  @Prop({ type: Number, default: 2 }) radius!: number;

  /**
   * After the index of the first element
   */
  public readonly AFTER_FIRST_ELEMENTS_INDEX: number = 2;
  /**
   * Delta between page and array
   */
  public readonly DELTA: number = 1;
  public readonly FIRST_PAGE_INDEX: number = 0;

  /**
   * D = 2 * R
   */
  public get diameter(): number {
    return this.radius * 2;
  }

  /**
   * Number of pages
   */
  public get countOfPages(): number {
    return Math.ceil(this.total / this.perPage) || 0;
  }

  public get renderBtnsArray(): number[] {
    const array = [this.DELTA];
    const firstPage = this.value - this.getDynamicRadius;
    const lastPage = this.value + this.getDynamicRadius;

    for (let i = firstPage; i <= lastPage; i++) {
      if (i >= this.DELTA && i <= this.countOfPages) {
        array.push(i);
      }
    }

    array.push(this.countOfPages);

    return array.filter(
      (item: number, index: number) => array.indexOf(item) === index
    );
  }

  private get pageIndex(): number {
    return this.value - this.DELTA;
  }

  /**
   * Dynamic radius to distribute the visibility of points
   */
  private get getDynamicRadius(): number {
    if (
      this.pageIndex === this.FIRST_PAGE_INDEX ||
      this.pageIndex === this.countOfPages - this.DELTA
    ) {
      return this.diameter;
    }

    if (
      this.pageIndex === this.DELTA ||
      this.pageIndex === this.countOfPages - this.AFTER_FIRST_ELEMENTS_INDEX
    ) {
      return this.diameter - this.DELTA;
    }

    return this.radius;
  }

  /**
   * Bottom of visible border elements
   */
  private get bottomOfBorder(): number {
    return this.pageIndex - this.renderBtnsArray.length;
  }

  /**
   * Top of visible border elements
   */
  private get topOfBorder(): number {
    return this.pageIndex + this.renderBtnsArray.length;
  }

  /*--- SHOW OR HIDE BUTTONS ---*/
  public hasHideNavBtn(page: number): boolean {
    const pageIndex: number = page - this.DELTA;
    return (
      !(pageIndex >= this.bottomOfBorder && pageIndex <= this.topOfBorder) &&
      !this.hasFirstOfLastPage(page)
    );
  }

  public hasRenderDots(index: number): boolean {
    const sufficientNumberOfElements: boolean =
      this.countOfPages > this.diameter; // sufficient number of the elements
    const isSecondIndex: boolean = index === this.DELTA; // should render after first of the element
    const isBeforeListIndex: boolean =
      index === this.renderBtnsArray.length - this.DELTA; // should render before last of the element
    return sufficientNumberOfElements && (isSecondIndex || isBeforeListIndex);
  }

  public hasHideDot(index: number): boolean {
    if (index === this.DELTA) {
      return (
        this.renderBtnsArray.indexOf(this.AFTER_FIRST_ELEMENTS_INDEX) >=
        this.FIRST_PAGE_INDEX
      );
    }

    return (
      this.renderBtnsArray.indexOf(this.countOfPages - this.DELTA) >=
      this.FIRST_PAGE_INDEX
    );
  }

  public hasFirstOfLastPage(page: number): boolean {
    return page === this.DELTA || page === this.countOfPages;
  }

  /*--- EMIT OF PAGE NUMBER ---*/
  public nextPage(): void {
    if (this.value < this.countOfPages) {
      const currentPage: number = this.value + this.DELTA;
      this.input(currentPage);
    }
  }

  public prevPage(): void {
    if (this.value > this.DELTA) {
      const currentPage: number = this.value - this.DELTA;
      this.input(currentPage);
    }
  }

  public updatePage(index: number): void {
    if (index !== this.value) {
      this.input(index);
    }
  }

  @Emit()
  private input(page: number): number {
    return page;
  }
}
