import Component, { mixins } from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import FormFieldMixin from "@/mixins/FormFieldMixin";
import { ValidationProvider } from "vee-validate";

@Component({
  components: { ValidationProvider },
})
export default class ValidationFieldMixin extends mixins(FormFieldMixin) {
  @Prop({ type: String, default: "" }) readonly rules!: string;
  @Prop({ type: String, default: "" }) readonly name!: string;
  @Prop({ type: String, default: "" }) readonly errorMessageClass!: string;

  @Emit()
  public input<T>(event: T): T {
    return event;
  }
}
