import { v4 } from "uuid";
import { TaskType } from "@/types/API/QuestionKitsType";

export const TASK_EMPTY_DATA: TaskType = {
  id: 0,
  title: "",
  // always four answers
  answers: [
    {
      id: v4(),
      title: "",
      is_right: false,
    },
    {
      id: v4(),
      title: "",
      is_right: false,
    },
    {
      id: v4(),
      title: "",
      is_right: false,
    },
    {
      id: v4(),
      title: "",
      is_right: false,
    },
  ],
};
