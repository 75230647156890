





















import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TextareaField from "@/components/form/TextareaField.vue";
import ValidationFieldMixin from "@/mixins/ValidationFieldMixin";
import { TEXTAREA_MIN_HEIGHT } from "@/constants/formInputsConstants";

@Component({
  components: {
    TextareaField,
  },
})
export default class ValidationTextareaField extends mixins(
  ValidationFieldMixin
) {
  @Prop({ type: Number, default: TEXTAREA_MIN_HEIGHT }) minHeight!: number;
}
